<template>
  <div class="grid">
    <div class="grid-cell flex-3" v-for="card in cards" :key="card.title">
      <div class="content-title">{{ card.contentTitle }}</div>
      <div class="grid-content">
        <h1>{{ card.title }}</h1>
        <p>{{ card.subtitle }}</p>
        <p class="subtitleBold">{{ card.subtitleBold }}</p>
        <p style="margin-top:0">{{ card.description }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["cards"],
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins";

.grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 50px 15% 0 15%;
}

.grid-cell {
  background-color:$secondary-color-9;
  border-radius: 8px 8px 48px 8px;
  box-shadow: 0px 4px 8px rgba(208, 215, 217, 0.4);
  overflow: hidden;
  position: relative;
  margin: 15px;
  padding: 20px;
  min-width: 190px;
}

.grid-content {
  border-left: 5px solid #f78c85;
  padding-left: 20px;
}

p {
  color:$secondary-color-10;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

h1 {
  color: $font-color-2;
  font-size: 42px;
}

.flex-3 {
  flex-basis: 20%;
}

.content-title {
  color: $secondary-color-7;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: .2rem;
}

.subtitleBold {
  color: $secondary-color-7;
  margin-bottom: 5px;
  font-weight: 600;
}
</style>
