<template>
  <Alert :isActive="isError" v-if="isError">{{ errorMessage }}</Alert>
  <Loading :isShow="isLoading" v-if="!isError">
    <Navigation></Navigation>
    <div class="theme-container">
      <PageTitle
        :title="pageTitle"
        :imgSrc="headerImage"
        :bgColor="backgroundColor"
        :showSlalomLogo="showSlalomLogo"
      ></PageTitle>
      <div class="container">
        <div class="sectionContainer sectionContainerTitle" v-if="openingSection" >
          <h1 class="main-title" v-html="openingSection.title"></h1>
          <h1 class="subtitle-title" v-html="openingSection.subtitle"></h1>
          <div class="descriptionCards">
            <div class="flex-row">
              <template
                v-for="card in openingSection.sectionCards"
                :key="card.title"
              >
                <div class="flex-4 descriptionCard">
                  <SingleCard
                    :title="card.title"
                    :subtitleBold="card.subtitle"
                    :contentText="card.content"
                  />
                </div>
              </template>
            </div>
          </div>
        </div>
        <div v-if="openingSection" class="sectionContainer pt-0">
          <h1 v-if="openingSection.subsectionTitle" class="subtitle-title-blue">
            {{ openingSection.subsectionTitle }}
          </h1>
          <div class="atAGlanceCards">
            <div class="flex-row justify-content-center">
              <template
                v-for="card in openingSection.subsectionCards"
                :key="card.title"
              >
                <div class="flex-3 atAGlanceCard">
                  <SingleCard
                    :subtitle="card.title"
                    :contentText="card.content"
                  />
                </div>
              </template>
            </div>
          </div>
        </div>
        <div v-if="valuesSection" class="sectionContainerGuiding">
          <h1 class="main-title">
            {{ valuesSection.title }}
          </h1>
          <p class="subtitle">
            {{ valuesSection.subtitle }}
          </p>
          <template v-if="valuesSection.description">
            <div v-html="valuesSection.description"></div>
          </template>
          <div class="guidingValuesCards">
            <div class="flex-row justify-content-center align-items-stretch">
              <template
                v-for="card in valuesSection.valueCards"
                :key="card.title"
              >
                <div class="flex-auto guidingValuesCard">
                  <SingleCard
                    :subtitleBold="card.title"
                    :contentText="card.description"
                    :imgSrc="[card.image]"
                  />
                </div>
              </template>
            </div>
          </div>
        </div>
        <div v-if="servicesSection" class="sectionContainer servicesSection">
          <h1 class="main-title">
            {{ servicesSection.title }}
          </h1>
          <template v-if="servicesSection.subtitle">
            <h1
              v-html="servicesSection.subtitle"
              class="subtitle-title"
            ></h1>
          </template>
          <template v-if="servicesSection.smallText">
            <div
              v-html="servicesSection.smallText"
              class="white-subtitle-title"
            ></div>
          </template>
          <div class="servicesCards">
            <div class="flex-row">
              <div class="col-12 col-sm-6">
                <template
                  v-for="(card, index) in servicesSection.servicesCards"
                  :key="card.title"
                >
                    <div :class="`servicesCard me-0 me-sm-2 mb-3 ${index%2==1?'d-sm-none':''}`">
                      <CollapsableContent
                        :title="card.title"
                        :contentHtml="card.content"
                        :listContent="card.list"
                      />
                    </div>
                </template>
              </div>
              <div class="d-none d-sm-block col-6">
                <template
                  v-for="(card, index) in servicesSection.servicesCards"
                  :key="card.title"
                >
                  <template v-if="index % 2 == 1">
                    <div :class="`servicesCard ms-2 mb-3`">
                      <CollapsableContent
                        :title="card.title"
                        :contentHtml="card.content"
                        :listContent="card.list"
                      />
                    </div>
                  </template>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class=" alt-color-container">
        <div v-if="expertiseSection" class="container sectionContainer expertiseSection">
          <h1 class="main-title">{{ expertiseSection.title }}</h1>
          <p class="subtitle">{{ expertiseSection.subtitle }}</p>

          <template v-if="expertiseSection.description">
            <div class="description pb-4"
              v-html="expertiseSection.description"
            ></div>
          </template>
          <template
            v-for="section in expertiseSection.cardSection"
            :key="section.title"
          >
            <h1 class="subtitle pt-5 pb-3">
              {{ section.title }}
            </h1>
            <div class="lifeSciencesCards">
              <div class="flex-row">
                <template v-for="card in section.cards" :key="card.title">
                  <div class="flex-3 lifeSciencesCard">
                    <SingleCard
                      :subtitle="card.title"
                      :contentText="card.content"
                    />
                  </div>
                </template>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="grey-container">
        <div v-if="impactInnovationSection" class="sectionContainer container impactInnovationSection">
          <h1 class="main-title" style="font-size: 32px; margin-bottom: 40px">
            {{ impactInnovationSection.title }}
          </h1>
          <div class="expertiseCards">
            <div class="flex-row">
              <div class="col-12 col-sm-6">
                <template
                  v-for="(card, index) in impactInnovationSection.impactCards"
                  :key="card.title"
                >
                  <div :class="`expertiseCard me-0 me-sm-2 mb-3 ${index%2==1?'d-sm-none':''}`">
                    <CollapsableContent
                      :title="card.title"
                      :contentHtml="card.paragraph"
                      :listContent="card.listContent"
                    />
                  </div>
                </template>
              </div>
              <div class="d-none d-sm-block col-6">
                <template
                  v-for="(card, index) in impactInnovationSection.impactCards"
                  :key="card.title"
                  >
                  <template v-if="index % 2 == 1">
                    <div :class="`expertiseCard ms-2 mb-3`">
                      <CollapsableContent
                        :title="card.title"
                        :contentHtml="card.paragraph"
                        :listContent="card.listContent"
                      />
                    </div>
                  </template>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div v-if="clientsSection" class="sectionContainer">
          <h1 style="font-size: 32px" v-html="clientsSection.title" />
          <template v-if="clientsSection.subtitle">
            <div v-html="clientsSection.subtitle"></div>
          </template>
          <ImageGrid :images="clientsSection.clientLogos" />
        </div>
      </div>
      <div class="container">
        <div v-if="linkSection" class="card-container">
          <Card
            :title="linkSection.title"
            :content="linkSection.description"
            :imgSrc="linkSection.image"
            :flipImage="true"
            :buttonLink="{
              link: linkSection.button.url,
              text: linkSection.button.displayText,
            }"
          />
        </div>
      </div>
      <div class="grey-container">
        <div v-if="collaborationSection" class="sectionContainer container partnership-container">
          <h1 class="main-title" v-html="collaborationSection.title"></h1>
          <template v-if="collaborationSection.description">
            <div
              id="grow-slalom"
              class="subtitle"
              v-html="collaborationSection.description.text"
            ></div>
          </template>
          <div class="partnerships">
            <template
              v-for="partnership in collaborationSection.partnerships"
              :key="partnership.partnershipLogo"
            >
              <PartnershipInfo
                :imgSrc="partnership.partnershipLogo"
                :partnershipInfos="partnership.partnershipInfos"
              />
            </template>
          </div>
          <div class="partner-award-wrapper">
            <div class="partner-award-column">
              <template
                v-for="award in collaborationSection.awardSection.awards"
                :key="award.awardTitle"
              >
                <div class="col-12 col-md-6">

                  <div style="font-weight: 600">{{ award.awardTitle }}</div>
                  <template v-if="award.awardValue">
                    <div class="award-value" v-html="award.awardValue"></div>
                  </template>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div v-if="recognitionSection" class="sectionContainer container">
        <h1 v-html="recognitionSection.title"></h1>
        <div class="recognition-wrapper">
          <div class="recognition-column">
            <template
              v-for="recognition in recognitionSection.recognition"
              :key="recognition.recognitionImage"
            >
              <div class="col-12 col-sm-6 col-lg-4 d-flex">
                <div class="award m-2 d-flex align-items-center justify-content-center flex-column w-100">
                    <div v-if="recognition.issuer" class="award_issuer">{{recognition.issuer}}</div>
                    <div v-if="recognition.awardTitle" class="award_title">{{recognition.awardTitle}}</div>
                    <div v-if="recognition.awardSubtitle" class="award_subtitle">{{recognition.awardSubtitle}}</div>
                    <div v-if="recognition.issued" class="award_issued">{{recognition.issued}}</div>
                    <div v-if="recognition.recognitionImage" class="award_recognitionImage">
                      <img :src="recognition.recognitionImage" class="recognition-img" />
                    </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="alt-color-2-container">
        <div v-if="feedbackSection" class="sectionContainer feedbackSection container">
          <div class="feedback-section">
            <h1 style="margin-bottom: 20px">
              {{ feedbackSection.title }}
            </h1>
            <Signature
              :name="feedbackSection.signature.name"
              :signature="feedbackSection.signature.signaturePicture"
              :title="feedbackSection.signature.position"
              :picture="feedbackSection.signature.picture"
            >
            </Signature>
            <div class="feedback-contact-text">
              <p class="decorated-white-text">
                <a :href="`mailto:${feedbackSection.email}`">{{ feedbackSection.email }}</a>
              </p>
              <p class="decorated-white-text">
                <a :href="`tel:${feedbackSection.phone}`">{{ feedbackSection.phone }}</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <BottomNavigator :id="whoIsSlalomPageId" />
  </Loading>
</template>

<script>
import api from "@/services/api.js";
import Alert from "@/components/Alert.vue";
import BottomNavigator from "@/components/BottomNavigator.vue";
import Card from "@/components/Card.vue";
import CardGrid from "@/components/CardGrid.vue";
import GuidingValueCard from "@/components/GuidingValueCard.vue";
import ImageGrid from "@/components/ImageGrid.vue";
import Loading from "@/components/Loading.vue";
import Navigation from "@/components/Navigation.vue";
import PageTitle from "@/components/PageTitle.vue";
import PartnershipInfo from "@/components/PartnershipInfo.vue";
import Signature from "@/components/Signature.vue";
import SingleCard from "@/components/SingleCard.vue";
import CollapsableContent from "@/components/CollapsableContent.vue"

export default {
  data: () => ({
    isLoading: false,
    isError: false,
    errorMessage: "",
    pageTitle: "",
    headerImage: "",
    backgroundColor: "",
    showSlalomLogo: false
  }),
  computed: {
    whoIsSlalomPageId() {
      const whoIsSlalomPage = this.$store.state.pages.slalom;
      if (whoIsSlalomPage) {
        return whoIsSlalomPage.id;
      }
      return null;
    },
  },
  methods: {
    async getData() {
      this.isError = false;
      this.isLoading = true;
      try {
        const { data } = await api.getWhoIsSlalomPage(this.whoIsSlalomPageId);
        this.pageTitle = data.pageTitle;
        this.headerImage = data.pageIcon;
        this.backgroundColor = "#eeeeee";
        this.openingSection = {
          ...data.openingSection,
          title: (data.openingSection.title||'')
            .replace(/<altColor>/g, '<span class="altColor">')
            .replace(/<\/altColor>/g, "</span>"),
          subtitle: (data.openingSection.subtitle||'')
            .replace(/<altColor>/g, '<span class="altColor">')
            .replace(/<\/altColor>/g, "</span>"),          sectionCards: data.openingSection.sectionCards.map((card) => ({
            ...card,
            content: card.content ? card.content.text : null,
          })),
          subsectionCards: data.openingSection.subsectionCards.map((card) => ({
            ...card,
            content: card.content ? card.content.text : null,
          })),
        };
        this.valuesSection = data.valuesSection ? {
          ...data.valuesSection,
          description: data.valuesSection.description
            ? data.valuesSection.description.text
            : null,
          valueCards: data.valuesSection.valueCards.map((card) => ({
            ...card,
            description: card.description ? card.description.text : null,
          })),
        } : null;
        this.servicesSection = {
          ...data.servicesSection,
          subtitle: data.servicesSection.subtitle
            ? data.servicesSection.subtitle.text
            : null,
          smallText: data.servicesSection.smallText
            ? data.servicesSection.smallText.text
            : null,
          servicesCards: data.servicesSection.servicesCards.map((card) => ({
            ...card,
            content: card.content ? card.content.text : null,
          })),
        };
        this.expertiseSection = {
          ...data.expertiseSection,
          description: data.expertiseSection.description
            ? data.expertiseSection.description.text
            : null,
          cardSection: data.expertiseSection.cardSection.map((section) => ({
            ...section,
            cards: section.cards.map((card) => ({
              ...card,
              content: card.content ? card.content.text : null,
            })),
          })),
        };
        this.impactInnovationSection = {
          ...data.impactInnovationSection,
          impactCards: data.impactInnovationSection.impactCards.map(
            (impact) => ({
              ...impact,
              listContent: impact.listContent
                ? impact.listContent.map((list) => list.text)
                : [],
            })
          ),
        };
        this.clientsSection = data.clientsSection ? {
          ...data.clientsSection,
          title: (data.clientsSection.title||'')
            .replace(/<altColor>/g, '<span class="altColor">')
            .replace(/<\/altColor>/g, "</span>"),
          subtitle: data.clientsSection.subtitle
            ? data.clientsSection.subtitle.text
            : null
        } : null;
        this.linkSection = {
          ...data.linkSection,
          description: data.linkSection.description
            ? data.linkSection.description.text
            : null,
        };

        this.collaborationSection = {
          ...data.collaborationSection,
          title: (data.collaborationSection.title||'')
            .replace(/<altColor>/g, '<span class="altColor">')
            .replace(/<\/altColor>/g, "</span>"),
          awardSection: data.collaborationSection.awardSection
            ? {
                awards: data.collaborationSection.awardSection.awards.map(
                  (award) => ({
                    ...award,
                    awardValue: award.awardValue ? award.awardValue.text : null,
                  })
                ),
              }
            : null,
        };
        this.recognitionSection = {
          ...data.recognitionSection,
          title: data.recognitionSection.title
            .replace(/<altColor>/g, '<span class="altColor">')
            .replace(/<\/altColor>/g, "</span>")
            };
        this.feedbackSection = data.feedbackSection;
      } catch (err) {
        this.isError = true;
        this.errorMessage = err.message;
      } finally {
        this.isLoading = false;
      }
    },
  },
  watch: {
    whoIsSlalomPageId(newValue, oldValue) {
      if (newValue) {
        this.getData();
      }
    },
  },

  async beforeRouteUpdate(to, from, next) {
    next();
  },

  async created() {
    if (this.whoIsSlalomPageId) {
      await this.getData();
    }
  },

  components: {
    Alert,
    BottomNavigator,
    Card,
    CardGrid,
    GuidingValueCard,
    Loading,
    ImageGrid,
    Navigation,
    PageTitle,
    Signature,
    PartnershipInfo,
    SingleCard,
    CollapsableContent,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins";
.pl-1{
  padding-left: .5em;
}
.pr-1{
  padding-right: .5em;
}

#grow-slalom {
  p {
    font-size: 42px;
    font-weight: 600;
  }
}
.award-value {
  display: inline-block;
}
.theme-container {
  :deep(.altColor) {
    color: $alt-font-color-1;
  }
}

.sectionContainer {
  background-color: $secondary-color-9;
  @include padding(12 10 0 10);
  @include responsive(phone) {
    @include padding(4 1 4 1);
  }
  @include responsive(tablet) {
    @include padding(5 5 5 5);
  }
  .subtitle-title{
    margin-top: 1.5em;
  }
}

.sectionContainerTitle {

}

.sectionContainerGuiding {
  background-color: $secondary-color-9;
  @include padding(10 10 10 10);
  @include responsive(phone) {
    @include padding(4 1 4 1);
  }
  @include responsive(tablet) {
    @include padding(5 5 5 5);
  }
}

.descriptionCards {
  margin: 1em 0px;
  .descriptionCard {
    @include responsive(tablet) {
      flex: 4 0 100%;
    }
    :deep(.s_card) {
      padding: 15px;
      border-radius: 0px;
      box-shadow: none;
      .cardHeader {
        color: #34373f; //change to variable, Check to see if this hex code exists in settings.scss
        letter-spacing: 0.2rem;
      }
      .subtitleBold {
        font-size: 14px;
        color: $secondary-color-7;
        font-weight: 600;
      }
      .contentText {
        font-size: 14px;
        color: $secondary-color-10;
      }
      .grid-content {
        border-left: 5px solid $border-color-1;
      }
    }
  }
}
.atAGlanceCards {
  .atAGlanceCard {
    @include responsive(tablet) {
      flex: 0 3 50%;
    }
    :deep(.s_card) {
      margin: 15px;
      padding: 15px;
      min-width: 203px;
      .cardHeader {
      }
      .subtitle {
        font-size: 42px;
        font-weight: 600;
      }
      .contentText {
        font-size: 14px;
        color: $secondary-color-10;
      }
      .grid-content {
        border-left: 5px solid $border-color-2;
      }
    }
  }
  @for $i from 1 through 6 {
    .flex-row {
      .atAGlanceCard:nth-child(#{$i}){
        :deep(.s_card) {
          .grid-content {
            border-left: 5px solid map-get(nth($slalom-ordered-branding, $i),color);
          }
        }
      }
    }
  }  
}
.guidingValuesCards {
  @include responsive(phone) {
    padding: 3em;
  }
  .guidingValuesCard {
    margin: 15px;
    padding: 20px;
    box-shadow: 0px 4px 8px rgba(208, 215, 217, 0.4);
    @include responsive(phone) {
      flex: 1 0 100%;
    }
    @include responsive(tablet) {
    }
    background-color: $secondary-color-4;
    :deep(.s_card) {
      border-radius: 0px;
      box-shadow: none;
      .cardHeader {
      }
      .img-wrapper {
        margin-bottom: 1em;
      }
      .subtitleBold {
        font-size: 1.25em;
        line-height: 1;
        height: 50px;
      }
      .contentText {
      }
      .grid-cell {
        width: 195px;
        @include responsive(phone) {
          width: auto;
        }
      }
      .grid-content {
        padding-left: 0px;
        padding-right: 0px;
        margin-bottom: 1.5em;
      }
    }
  }
}
.servicesSection{
  padding-top: 4em;
  padding-bottom: 6em;
  .servicesCards {
    margin-top:2em;
    @include responsive(phone) {
      padding: 3em;
    }
    .servicesCard {
      padding: 20px;
      border-radius: 10px;
      @include bg-color($secondary-color-4);
      //background-color: $secondary-color-5;
      @include responsive(phone) {
        flex: 1 0 100%;
      }
      @include responsive(tablet) {
      }
      :deep(.s_card) {
        border-radius: 0px;
        box-shadow: none;
        .cardHeader {
        }
        .img-wrapper {
          margin-bottom: 1em;
        }
        .subtitleBold {
          font-size: 1.25em;
          line-height: 1;
          height: 50px;
        }
        .contentText {
          font-size: 14px;
          font-weight: 300;
        }
        .list-wrapper {
          font-size: 14px;
          font-weight: 300;
        }
        .grid-cell {
          width: 195px;
          @include responsive(phone) {
            width: auto;
          }
        }
        .grid-content {
          padding-left: 0px;
          padding-right: 0px;
          margin-bottom: 1.5em;
        }
      }
    }
  }
}

.lifeSciencesCards {
  .lifeSciencesCard {
    @include responsive(tablet) {
      flex: 0 3 50%;
    }
    :deep(.s_card) {
      margin: 15px;
      padding: 15px;
      min-width: 203px;
      background-color: $card-backgroundColor-1;
      .cardHeader {
      }
      .subtitle {
        font-size: 42px;
        font-weight: 600;
      }
      .contentText {
        font-size: 14px;
        color: $secondary-color-10;
      }
      .grid-content {
        border-left: 5px solid $border-color-2;
      }
    }
  }
  @for $i from 1 through 6 {
    .flex-row {
      .lifeSciencesCard:nth-child(#{$i}){
        :deep(.s_card) {
          .grid-content {
            border-left: 5px solid map-get(nth($slalom-ordered-branding, $i),color);
          }
        }
      }
    }
  }  
}
.lifeScienceCustomersCards {
  .lifeScienceCustomersCard {
    @include responsive(tablet) {
      flex: 0 3 50%;
    }
    :deep(.s_card) {
      margin: 15px;
      padding: 15px;
      min-width: 203px;
      background-color: $card-backgroundColor-1;
      .cardHeader {
      }
      .subtitle {
        font-size: 42px;
        font-weight: 600;
      }
      .contentText {
        font-size: 14px;
        color: $secondary-color-10;
      }
      .grid-content {
        border-left: 5px solid $border-color-2;
      }
    }
  }
}
.expertiseCards {
  @include responsive(phone) {
    padding: 3em;
  }
  .expertiseCard {
    padding: 20px;
    border-radius: 10px;
    background-color: $secondary-color-9;
    @include responsive(phone) {
      flex: 1 0 100%;
    }
    @include responsive(tablet) {
    }
    :deep(.s_card) {
      border-radius: 0px;
      box-shadow: none;
      .cardHeader {
      }
      .img-wrapper {
        margin-bottom: 1em;
      }
      .subtitleBold {
        font-size: 1.25em;
        line-height: 1;
        height: 50px;
      }
      .contentText {
        font-size: 14px;
        font-weight: 300;
      }
      .list-wrapper {
        font-size: 14px;
        font-weight: 300;
      }
      .grid-cell {
        width: 250px;
        @include responsive(phone) {
          width: auto;
        }
      }
      .grid-content {
        padding-left: 0px;
        padding-right: 0px;
        margin-bottom: 1.5em;
      }
    }
  }
}
.filler {
  margin: 25px 0;
}

.life-science-customers {
  display: flex;
  justify-content: left;
}

.video-img {
  margin: 25px 0 100px 0;
}

.theme-container {
  @include relative;
  @include margin(0 0 10 0);
  :deep(.page-title) {
    .title-container {
      background-color: map-get($colors, black);
    }
  }
}

.recognition-flex {
  @include padding(12);
  @include responsive(phone) {
    @include padding(4 1 4 1);
  }
  @include responsive(tablet) {
    @include padding(5 5 5 5);
  }
  :deep(.flex-3) {
    flex-basis: 20%;
  }
}

.alt-color-container {
  background-color: map-get($colors, slalom-dark-blue);

  .container{
    background-color:inherit;
  }
  .main-title{
    color: $secondary-color-9;
  }
  .subtitle{
    color: $secondary-color-9;
  }
  .description{
    color: $secondary-color-9;
  }

}


.grey-container {
  background-color: $secondary-color-4;
  .container{
    background-color:inherit;
  }
}

.alt-color-2-container {
	@include bg-color($secondary-color-7);
  .container{
    background-color:inherit;
  }
}

.sorairo-dark-blue-container {
  .feedback-section {
    h1 {
      color: $secondary-color-9;
    }
  }
  background-color: $secondary-color-5;
  @include padding(6 12 6 12);
  @include responsive(phone) {
    @include padding(4 1 4 1);
  }
  @include responsive(tablet) {
    @include padding(5 5 5 5);
  }
  :deep(.main-content-container) {
    background-color: unset;
    margin-bottom: 0;
  }
  :deep(.signature-dark) {
    color: $secondary-color-9;
  }
  :deep(.signature-light) {
    color: $secondary-color-9;
  }
}

.main-title {
  color: $font-color-1;
  font-size: 48px;
  margin-bottom: 12px;
  @include responsive(phone) {
    font-size: 22px;
  }
}

.main-title-white {
  color: $secondary-color-9;
  font-size: 48px;
  margin-bottom: 12px;
  @include responsive(phone) {
    font-size: 22px;
  }
}
.subtitle-title {
  font-size: 2em;
  margin-bottom: 12px;
  @include responsive(phone) {
    font-size: 18px;
  }
}
.subtitle-title-blue {
  color: map-get($colors, slalom-blue);
  font-size: 32px;
  margin-bottom: 12px;
  @include responsive(phone) {
    font-size: 18px;
  }
}

.subtitle-title-white {
  color: $secondary-color-9;
  font-size: 28px;
  margin-bottom: 24px;
  margin-top: 24px;
  @include responsive(phone) {
    font-size: 18px;
  }
}

.subtitle {
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 0px;
}

.blue-subtitle {
  color: $secondary-color-1;
  @include margin(2 0 2 0);
  font-size: 24px;
  @include responsive(phone) {
    font-size: 18px;
  }
}

.feedback-contact-text {
  color: $secondary-color-1;
}

.decorated-white-text {
  color: $secondary-color-9;
  text-decoration: underline;
  margin-bottom: 0;
  margin-top: 0;
}

.pad-12 {
  @include padding(1 0 1 0);
}
.expertiseSection, .impactInnovationSection, .feedbackSection, .partnership-container{
  padding-bottom:3rem;
}
.client-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.client-image-container img {
  max-width: 100%;
}
.partnerships{
  :deep(.row-wrapper){
    @for $i from 1 through 6 {
      div:nth-child(#{$i}) {
        border-color: map-get(nth($slalom-ordered-branding, $i),color);
      }
    }
  }
}
.partnership {
  @for $i from 1 through 6 {
    div:nth-child(#{$i}) {
      .award{
        background-color: map-get(nth($slalom-ordered-branding, $i),color);
        color: map-get(nth($slalom-ordered-branding, $i),font-color);
      }
    }
  }
}
.partner-award-wrapper {
  margin-top: 50px;
}
.partner-award-column {
 // flex: 50%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    @include responsive(phone) {
      max-height: none;
    }
    
}

.partner-award-column p {
  margin-top: 0;
  margin-bottom: 10px;
  width:50%;

  @include responsive(phone) {
    width:100%;
  }
}

.recognition-wrapper {
  @include padding(4 0 4 0);
  display: flex;
  margin-bottom: 30px;
  @include responsive(phone) {
    @include padding(0);
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
.recognition-column {
  //flex: 33.3%;
  display: flex;
    flex-wrap: wrap;
    flex: 0 0 100%;
    margin: auto;
    width: 50%;
    @for $i from 1 through 6 {
      div:nth-child(#{$i}) {
        .award{
          background-color: map-get(nth($slalom-ordered-branding, $i),color);
          color: map-get(nth($slalom-ordered-branding, $i),font-color);
        }
      }
    }
    .award{
      padding:20px;
      &>div{
        text-align:center;
      }
      .award_issuer{
        text-transform: Uppercase;
        font-size:18px;
        margin-bottom:5px;
      }
      .award_title{
        font-weight: 600;
        font-size: 27px;
        line-height: 34px;
        margin-bottom:5px;

      }
      .award_subtitle{
        font-weight: 400;
        font-size: 17px;
        line-height: 19px;
        margin-bottom:5px;

      }
      .award_issued{
        font-style: italic;
        font-weight: 400;
        font-size: 18px;
      }
    }
}

.recognition-img {
  max-width: 100%;
  margin: auto;
  display: block;
  margin-bottom: 25px;
  @include responsive(phone) {
    max-width: 90%;
  }
}

.card-container {
  :deep(.rectangleCard-container) {
    @include bg-color(map-get($colors,slalom-dark-blue));
    //background-color: $secondary-color-5;
  }
  :deep(h1) {
    color: $secondary-color-9;
    font-weight: 500;
  }
  :deep(p) {
    color: $secondary-color-9;
    font-weight: 300;
  }
  @include padding(16 8 16 8);
  @include responsive(phone) {
    @include padding(1 1 1 0);
  }
  @include responsive(tablet) {
    @include padding(4 4 4 4);
  }
}
.feedbackSection{
  :deep(.main-content-container){
    background-color:map-get($colors, no-color);
  }
  :deep(.signature-image){
    border-color:map-get($colors, dark-gray);
  }
  :deep(.signature-dark){
    color:map-get($colors, takeda-white);
  }
  :deep(.signature-light){
    color:map-get($colors, takeda-white);
  }
}
</style>
