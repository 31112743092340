<template>
  <div class="rectangleCard-container">
    <div v-if="titleImgSrc">
      <img :src="titleImgSrc" alt="test" class="list-card-image" />
    </div>
    <div class="flex-container">
      <v-template v-if="imgSrc && flipImage" class="col-12 col-lg position-relative">
        <div class="flex-6 mx-3 mx-md-0 img-container" v-if="flipImage">
          <img :src="imgSrc" alt="Slalom White Logo" class="card-image" />
        </div>
      </v-template>
      <div :class="`flex-${imgSrc ? 6 : 12} ${flipImage ? 'ms-lg-5 ps-lg-5' : 'me-lg-5 pe-lg-5'} pt-5 pt-lg-5 content`">
        <h1>{{ title }}</h1>
        <p v-html="content" />
        <div v-for="item in optionalContent" :key="item">
          <p class="body-text"> {{item}} </p>
        </div>
        <div v-for="item in linkList" :key="item">
          <a v-if="item.link" style="text-decoration: none; color: inherit;" :href="item.link" target="item.openInWindow !== false">
              Learn More
              <img class ="arrow" src='@/assets/images/next-arrow.svg' />
          </a>
        </div>
        <div class="link-button" v-if="buttonLink">
          <a :href="buttonLink.link" target="_blank">
              {{buttonLink.text}}
          </a>
        </div>
      </div>
      <v-template v-if="imgSrc && !flipImage" class="col-12 col-lg position-relative">
        <div class="flex-6 mx-3 mx-md-0 align-self-center img-container" v-if="!flipImage" >
          <img :src="imgSrc" alt="Slalom White Logo" class="card-image"/>
        </div>
      </v-template>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "title",
    "content",
    "optionalContent",
    "linkList",
    "imgSrc",
    "flipImage",
    "buttonLink",
    "titleImgSrc"
  ],
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins";

h1 {
  color: $primary-color-1;
}

p {
  font-weight: 400;
  color: $font-color-1;
  :deep(b){
    font-weight:600;
  }
}

a {
  display: flex;
  text-align: center;
  color: $secondary-color-7;
  font-weight: 600;
  font-size: 18px;
}

.img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0; left: 0; bottom: 0; right: 0;
  @include responsive(phone) {
    @include margin(0);
    min-width: 0;
    min-height: 0;
    max-width: 100%;
    position: relative;
  }
  @include responsive(tablet) {
    @include margin(0);
    min-width: 0;
    min-height: 0;
    max-width: 100%;
    position: relative;
  }
  img{
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.2);
  }
}
.list-card-image {
  @include border-radius(2);
  max-width: 95%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.card-image {
  width:100%;
  @include responsive(phone) {
    position:static;
  }
  @include responsive(tablet) {
    position:static;
  }
}

.content {
    @include responsive(phone) {
    @include padding(0 4 0 4);
  }
  @include responsive(tablet) {
    @include padding(0);
  }
}

.arrow {
  margin-left: 10px;
}

.rectangleCard-container {
  background-color: $secondary-color-4;
  @include padding(8);
  border-bottom-right-radius: 4em;
  position: relative;

  @include responsive(phone) {
    @include margin(1 1 1 1);
    @include padding(4 0 4 0);
  }
  @include responsive(tablet) {
    @include margin(5 5 5 5);
    @include padding(5 5 5 5);
  }
}

.link-button {
  @include animate;
  @include padding(1 3 1 3);
  display: inline-block;
  border-radius: 4px 4px 16px 4px;
  border: 2px solid #FFFFFF; 
  &:hover {
    opacity: 0.7;
  }
  & a {
    text-decoration: none; 
    font-weight:500; 
    font-size: 18px; 
  }
}

</style>
