<template>
  <div class="outer-wrapper">
    <div><img :src="imgSrc" alt="PartnerLogo" /></div>
    <div class="row-wrapper">
      <template v-for="partnershipInfo in partnershipInfos">
        <div class="data-wrapper">
          <p class="value">{{ partnershipInfo.value }}</p>
          <p class="sub-text">{{partnershipInfo.title}}</p>
        </div>
      </template>
    </div>
    <div class="line mt-5"/>
  </div>
</template>

<script>
export default {
  props: ["imgSrc", "partnershipInfos"],
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins";

img {
  @include margin(2 0 2 0);
}
.line{
  height:1px;
  width:75%;
  background: $secondary-color-8;
}
.data-wrapper {
  border-left: 5px solid #ffffff;
  flex: 0 4 25%;
  padding-left: 20px;
  @include responsive(tablet) {
    padding-left: 6px;
    border-left: 4px solid #ffffff;
  }
}
.value {
  color: $primary-color-6;
  font-size: 32px;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 0px;
  @include responsive(tablet) {
    font-size: 24px;
  }
}
.sub-text {
  color: $secondary-color-7;
  font-size: 14px;
  font-weight: 300;
  margin-top: 0px;
  @include responsive(tablet) {
    font-size: 12px;
  }
}
.outer-wrapper {
  width: 50%;
  display: inline-block;
  margin-bottom: 25px;
}
.row-wrapper {
  display: flex;
  @include responsive(phone) {
    display: inline-block;
  }
}
</style>
