<template>
    <div class= "navigator-container">
        <router-link :to="previous.route">
          <img src='@/assets/images/page_nav-hover.svg' />
          {{previous.name}}
        </router-link>
        <div class="divider"> </div>
        <router-link :to="next.route">
          {{next.name}}
          <img class="next-arrow" src='@/assets/images/page_nav-hover.svg' />
        </router-link>
    </div>
</template>

<script>
import * as constants from "@/components/constants.js";

export default {
  props: ["id"],
  data() {
  },
  computed: {
		pages () {
		const defaultLinks = []
		const data = this.$store.state.rootItem;
		if(data){
        return [constants.HomeNavItem,...data.tilePages.map((tilePage)=>({
          name: tilePage.pageTitle,
          route: tilePage.routeTo,
          imgSrc: tilePage.menuIcon,
          id: tilePage.id
        }))];
      }
		return defaultLinks;
		},
    currentPageIndex () {
      let index = 0;
      if(this.pages.length != 0){
        for(let i = 0; i< this.pages.length; i++){
          if(this.pages[i].id == this.id){
            index = i;
          }
        }
      }
      return index;
    },
    previous () { return this.pages[this.currentPageIndex - 1]||constants.HomeNavItem},
    next () { return this.pages[(this.currentPageIndex + 1) % this.pages.length]||constants.HomeNavItem},

	},

};

</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins";

.navigator-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 65px;
  font-weight: 600;  
  @include responsive(phone) {
   column-gap: 10px;
   font-size: 12px;
  }
  a{
    color:map-get($colors, slalom-blue);
    text-decoration:none;
    &:hover{
      text-decoration:underline;
    }
  }
}

img {
    @include padding(0 2 0 2);
}

a {
  display: flex;
  text-align: center;
  color: $secondary-color-7;
}

.divider {
    background-color: $secondary-color-8;
    width: 1px;
}
.next-arrow{
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

</style>
