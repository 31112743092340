<template>
  <div class="flex-container page-title">
    <div class="flex-4 title-container ">
      <img class="frame-img" :src="imgSrc" />
      <h1 class="frame-title">{{ title }}</h1>
      <img src="@/assets/images/slalom-white.svg" alt="Slalom White Logo" style="margin-bottom:3px; margin-left: 10px" v-if="showSlalomLogo"/>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title", "imgSrc", "showSlalomLogo"],
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins";
@import "@/assets/scss/_settings";

.title-container {
  @include padding(3 8 3 8);
  border-radius: 0px 8px 64px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  @include responsive(phone) {
    @include padding(3 2 3 2);
  }
}

.frame-title {
  color: $secondary-color-9;
  @include padding(0 0 0 2);
  font-size: 42px;
  @include responsive(phone) {
    font-size: 28px;
  }
}

h1 {
  white-space: nowrap;
  @include responsive(phone) {
    white-space: normal;
  }
  @include responsive(tablet) {
    white-space: normal;
  }
}
</style>
