<template>
    <div class="main-content-container">
        <img
            class="picture"
            :src="picture"
            alt=""
        />
        <img
            class="signature-image"
            :src="signature"
            alt=""
        />
        <div class="signature-container">
            <p class='signature-dark'>{{ name }}</p> 
            <p class='signature-light'>{{ ' | ' +  title }}</p>
        </div>
    </div>

</template>

<script>
export default {
	props: [
		'picture',
		'signature',
		'name',
		'title',
	],
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins";

.main-content-container {
  background-color: $secondary-color-9;
  flex-direction: column;
  margin-bottom: 25px;
  
}

.picture {
    max-width: 100%;
    padding-right: 20px;
}


.signature-image {
    max-width: 100%;
    padding-left: 20px;
    border-left:   5px solid  $secondary-color-1;
}

.signature-container {
    display: flex;
    flex-direction: row;
}

.signature-dark {
    font-size: 18px;
    color: $secondary-color-7;
    @include responsive(phone) {
        font-size: 1.8vh;
    }
}

.signature-light {
    font-size: 18px;
    color: $secondary-color-8;
    white-space: pre;
    @include responsive(phone) {
        font-size: 1.8vh;
    }
}
</style>
