<template>
  <div class="wrapper">
    <div class="c_row" v-for="column in images" :key="column">
      <div class="column" v-for="src in column" :key="src">
        <img :src="src" alt />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["images"],
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins";
.c_row {
  flex: 0 0 20%;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.column {
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  @include responsive(phone) {
    flex: 1 0 10%;
  }
}

.column img {
  margin-top: 8px;
  max-width: 100%;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  padding-top: 60px;
  padding-bottom: 60px;
  @include responsive(phone) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
</style>
