<template>
    <div class="collapseableContent">
        <div v-on:click="toggleExpand" :class="`expandTitle ${contentHtml ? 'clickable' : ''}`">
            <span>{{title}}</span><span v-if="contentHtml||listContent"><i :class="`fa-solid fa-chevron-down icon ${!isCollapsed ? 'flip' : ''}`"></i></span>
        </div>
        <template v-if="contentHtml||listContent">
            <div :class="`content ${isCollapsed ? 'collapsed' : ''}`">
                <div class="mt-2">
                    <template v-if="contentHtml">
                        <div v-html="contentHtml"/>
                    </template>
                    <div v-if="listContent" class="list-wrapper">
                        <ul>
                            <template v-for="item in listContent" :key="item">
                                <template v-if="item">
                                    <li v-html="item">
                                    </li>
                                </template>
                            </template>
                        </ul>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>
<style lang="scss" scoped>
    .collapseableContent{
        .expandTitle{
            display:flex;
            justify-content: space-between;
        }
        .icon{
            transition-duration: .5s;
            &.flip{
                transform: rotate(180deg);
            }
        }
        .content{
            font-size: .8em;
            overflow:hidden;
            transition: max-height 1s ease-in-out;
            max-height: 1500px;
            &.collapsed{
                max-height:0px;
                transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
            }
        }
        .clickable{
            &:hover{
                cursor: pointer;
            }
        }
    }
</style>
<script>
export default {
	data: () => ({
        isCollapsed: true
	}),
    props: {
        title: { type: String, default: ""},
        contentHtml: { type: String, default: ""},
        listContent: {type: Array}
    },

  methods:{
    toggleExpand: function () {
      this.isCollapsed = !this.isCollapsed;
    }
  },
	components: {

	}
}
</script>